<template>
  <b-card
    class="bg-light border"
    header-class="border-bottom"
  >
    <template #header>
      <h4
        class="d-flex align-items-center mb-0"
      >
        {{ headerTitle }}
      </h4>
    </template>

    <validation-observer
      ref="tagSetObserver"
      tag="form"
    >
      <form
        ref="form"
      >
        <b-row>
          <b-col
            cols="4"
            class="my-1"
          >
            <b-form-group
              label="Tags Set Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Tags Set Name"
                :rules="{required: true, isUnique: tagSet.id ? false : [allTagsets]}"
              >
                <b-form-input
                  v-model="tagSet.name"
                  placeholder="Name"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
            class="my-1"
          >
            <b-form-group
              label="Radio/Multi"
            >
              <validation-provider
                #default="{ errors }"
                name="Radio/Multi"
                rules="required"
              >
                <v-select
                  v-model="tagSet.select_type"
                  class="bg-white"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="tagsModesList"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
            class="my-1"
          >
            <b-form-group
              label="Tag Type Color"
            >
              <validation-provider
                #default="{ errors }"
                name="Tag Type Color"
                rules="required"
              >
                <compact-picker
                  :value="tagSet.color"
                  :palette="colorPalette"
                  class="color-picker"
                  @input="updateTagColor"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <div
              class="d-inline-block"
            >
              <template
                v-for="(tag, tagIndex) in tagSet.tags"
              >
                <b-form-tag
                  v-if="!tag.delete"
                  :key="tagIndex"
                  :title="tag.name"
                  class="mr-1 mb-1"
                  @remove="removeTag(tagIndex)"
                >{{ tag.name }}</b-form-tag>
              </template>
            </div>
            <b-form-group
              label="Add Tag Name"
            >
              <b-input-group class="mb-2">
                <b-form-input
                  v-model="newTagName"
                  placeholder="Press enter to add"
                  class="form-control"
                  @keypress.enter="addTag"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="addTag"
                  >
                    Add Tag
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </validation-observer>

    <template #footer>
      <b-row
        class="float-right"
      >
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="cancelChanges"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="mr-2"
          @click="applyChanges"
        >
          <span>Apply</span>
        </b-button>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import axios from '@axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, isUnique } from '@validations'

import {
  BRow, BCol, BCard, BButton, BFormInput, BFormGroup,
  BFormTag, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { Compact } from 'vue-color'

import useProgramTagsSetting from '@/views/admin/program/useProgramTagsSetting'

const initialTagSet = {
  name: '',
  select_type: null,
  color: '#7367F0',
  is_hidden: false,
  tags: [],
}
export default {
  name: 'TagSet',
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTag,
    BInputGroup,
    BInputGroupAppend,

    vSelect,
    'compact-picker': Compact,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    mode: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      allTagsets: [],
      tagSet: null,
      newTagName: '',
      tagsModesList: ['Radio', 'Multiple'],
      colorPalette: [
        '#2BA7E0', '#7367F0', '#8618F3', '#C73CDE', '#CC63D6', '#D85499', '#FC8BA8',
        '#C52953', '#D84C3D', '#EB9246', '#EECF51', '#9EC92A', '#80CF7A', '#65C2AC',
      ],
      required,
      isUnique,
    }
  },
  setup() {
    const {
      programId,
      postTagSet,
      updTagSet,
    } = useProgramTagsSetting()

    return {
      programId,
      postTagSet,
      updTagSet,
    }
  },
  computed: {
    headerTitle() {
      return this.mode === 'create' ? 'Adding Tags List' : 'Edit Tags List'
    },
  },
  created() {
    this.initData()
    this.fetchAllTagsets()
  },
  methods: {
    async fetchAllTagsets() {
      const queryParams = {
        programId: this.programId,
      }
      const response = await axios.get('/auth/tag-sets', { params: queryParams })
      this.allTagsets = response.data.data
    },
    initData() {
      if (this.mode === 'create') {
        this.tagSet = JSON.parse(JSON.stringify(initialTagSet))
      } else {
        this.setEditableTagSetData()
      }
    },
    updateTagColor(val) {
      this.tagSet.color = val.hex
      this.tagSet.tags.forEach(tag => {
        // eslint-disable-next-line no-param-reassign
        tag.color = val.hex
      })
    },
    addTag() {
      if (!this.newTagName) {
        return
      }
      const tag = {
        name: this.newTagName,
        color: this.tagSet.color,
        delete: false,
      }
      this.tagSet.tags.push(tag)
      this.newTagName = ''
    },
    removeTag(index) {
      if (this.mode === 'create') {
        this.tagSet.tags.splice(index, 1)
      } else {
        this.tagSet.tags[index].delete = true
      }
    },
    cancelChanges() {
      if (this.mode === 'create') {
        this.$emit('cancelCreating')
      } else {
        this.$emit('cancelEditing')
      }
      this.initData()
    },
    applyChanges() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.tagSetObserver.validate().then(success => {
          if (success) {
            resolve(true)
            // eslint-disable-next-line no-unused-expressions
            this.mode === 'create' ? this.createTagSet() : this.updateTagSet()
          } else {
            reject()
          }
        })
      })
    },
    async createTagSet() {
      try {
        this.tagSet.program_id = this.programId
        const response = await this.postTagSet(this.tagSet)
        this.cancelChanges()
        this.$emit('addNewTagSet', response)
      } catch (error) {
        this.$emit('refetchData')
      }
    },
    async updateTagSet() {
      try {
        this.tagSet.program_id = this.programId
        const response = await this.updTagSet(this.tagSet)
        this.cancelChanges()
        this.$emit('updateEditedTagSet', { rowIndex: this.index, data: response })
      } catch (error) {
        this.$emit('refetchData')
      }
    },
    setEditableTagSetData() {
      const tagSet = JSON.parse(JSON.stringify(this.item))
      tagSet.tags.forEach(tag => {
        // eslint-disable-next-line no-param-reassign
        tag.delete = false
      })
      this.tagSet = tagSet
    },
  },
}
</script>

<style lang="scss">
.color-picker {
  &.vc-compact {
    width: 100%;
    padding: 10px;
  }
  .vc-compact-colors {
    margin-bottom: 0;
  }
  .vc-compact-color-item {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    margin: 4px;
  }
  .vc-compact-dot {
    position: absolute;
    width: 14px;
    height: 7px;
    top: 7px;
    left: 5px;
    border-left: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
    background: transparent;
    border-radius: 0;
    transform: rotate(-45deg);
    opacity: 1;
  }
}
.program-tags-list {
  background: transparent;
  padding: 0;
  border: none;
  margin-bottom: 0;
  .form-group {
    margin-bottom: 0;
  }
}
</style>
